import React, { useState } from "react";
import { Link } from "react-router-dom";
import { projects } from '../data/projects';


const WorkPage = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  // Filter projects based on selected category
  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.category.includes(activeCategory));

  return (
    
    <div className="max-w-[1350px] xl:max-w-[1450px] mx-auto px-8 py-12">
       {/* Headline */}
       <h1 className="mb-24 mt-12 font-sans text-6xl leading-tight md:text-6xl max-w-[1100px]">
        From concept to conversion, see how I've built brands and helped businesses grow through strategic design.
      </h1>
      {/* Categories Filter */}
      <div className="flex justify-start gap-8 mb-8">
        {["All", "Brand", "E-commerce", "Campaign", "Product", "Web"].map(
          (category) => (
            <button
              key={category}
              className={`text-sm font-normal tracking-widest ${
                activeCategory === category ? "text-white" : "text-gray-400"
              } hover:text-white`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          )
        )}
      </div>

     

      {/* Projects Grid */}
      <div className="grid grid-cols-6 gap-6 mb-16 md:grid-cols-6">
        {filteredProjects.map((project, index) => {
          return (
            <div
              key={project.id}
              className={`${project.colSpan} space-y-4 overflow-hidden`}
            >
              <Link to={`/project/${project.slug}`} className="block overflow-hidden group">
                <img
                  src={project.image}
                  alt={project.title}
                  className="object-contain w-full h-full transition-transform duration-300 transform group-hover:scale-110"
                />
              </Link>
              <p className="text-xs pt-4 text-gray-400  ">{project.description}</p>
              <Link to={`/project/${project.slug}`}>
                <h3 className="text-2xl font-sans pt-2 pb-16 hover:text-gray-300 max-w-[500px] transition duration-300 ease-in-out">
                  {project.title}
                </h3>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkPage;
