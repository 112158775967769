import React from 'react';
import { Link } from 'react-router-dom';

const NextProject = ({ nextProject }) => {
  return (
    <section className="bg-black text-white px-8">
      <div className="max-w-[1305px] mx-auto mt-12">
      {/* Next Project Image */}
      <div className=" flex justify-between ">
        <div className=" flex flex-start ">
            <p className="text-sm text-gray-400 mt-0 mr-4">Next Project</p>
            <div className="flex flex-col"> 
              <Link to={`/project/${nextProject.slug}`}>
                <img src={nextProject.image} alt={nextProject.title} className="w-full max-w-[550px]" />
              </Link>
              <Link to={`/project/${nextProject.slug}`}>
                  <h2 className="text-2xl max-w-[500px] mt-4">{nextProject.title}</h2>
                </Link>
              </div>
        </div>
     
          <div>
        <Link
        to="/work"
        className="group flex items-center space-x-2 text-white font-semibold text-1xl border-current pb-2 font-sans transition duration-300 ease-in-out hover:text-gray-400"
      >
        View all work
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
        </svg>
      </Link>
        </div>
      </div>
      </div>
    </section>
  );
};

export default NextProject;
