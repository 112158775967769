import React from "react";
import { FaLinkedinIn, FaTwitter, FaGithub } from "react-icons/fa";
import alexsepiaImg from "../images/alex-sepia.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-black text-white py-20 px-8">
      <div className="separator border-t py-10"></div>
      <div className="max-w-[1350px] flex flex-wrap justify-between">
        {/* Left section with desc. */}
        <div className="space-y-4">
          <h2 className="text-2xl font-sans">al.ma</h2>
          <p className="text-sm font-sans leading-relaxed max-w-[400px]">
            So... I’m a design leader with over 15 years of experience
            across brand, analog and digital design (and in the more recent years, code). I specialize in
            bridging creativity with strategy and technology, helping businesses grow through
            thoughtful, human-centered and reflective design solutions.
          </p>

          {/* Back to Top btn */}
          <button
            onClick={scrollToTop}
            className="group flex items-center space-x-2 text-white font-semibold text-1xl border-current pb-2 font-sans transition duration-300 ease-in-out hover:text-gray-400"
          >
            Back to Top
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
            </svg>
          </button>
        </div>

        {/* Contact and Location Section */}
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-sans">Get in touch</h3>
            <p className="text-sm font-sans mt-4">Alexandra Maracinaru</p>
            <p className="text-xs font-sans text-gray-400 mt-1 mb-4">Designer. Leader.  Maker. <a href="https://brandiaq.com" target="_blank" rel="noopener noreferrer" className=" text-white hover:text-gray-400 ">@brandiaq</a></p>
            <a
              href="mailto:am@brandiaq.com"
              className="text-sm font-sans  text:white hover:text-gray-400 transition duration-300 ease-in-out"
            >
              am@brandiaq.com
            </a>
          </div>
          <div>
            <h3 className="text-lg font-sans">Location</h3>
            <p className="text-sm font-sans text-gray-400">
              Copenhagen, Denmark
            </p>
          </div>
        </div>

        {/* Profile Image + Socials */}
        <div className="flex flex-col items-center space-y-4">
          {" "}
          <img
            src={alexsepiaImg}
            alt="My profile"
            className="w-36 h-48 object-cover rounded-full mb-4"
          />
         
          <div className="flex space-x-10">
            
            {" "}
            <a
              href="https://www.linkedin.com/in/alexandramaracinaru/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400"
            >
              <FaLinkedinIn size={16} />
            </a>
            <a
              href="https://twitter.com/alexmaracinaru"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400"
            >
              <FaTwitter size={16} />
            </a>
            <a
              href="https://github.com/alexmaracinaru"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400"
            >
              <FaGithub size={16} />
            </a>
          </div>
        
        </div>
        
      </div>

      {/* Bottom copyright and links */}
      <div className="mt-20 text-gray-400 text-xs md:flex md:justify-between">
        <div>
          © 2024 — Copyright al.ma{" "}
          <a
            href="https://www.linkedin.com/in/alexandramaracinaru/"
            className="text-gray-300 hover:text-white"
          >
          Alexandra Maracinaru
          </a>
          . All rights reserved.
        </div>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="#" className="hover:text-white">
            privacy
          </a>
          <a href="#" className="hover:text-white">
            terms
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
