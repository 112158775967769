import React, { useState } from "react";

const ContactPage = () => {
  return (
    <div className="py-20">
      <div className="max-w-[1350px] m-auto">
        <h1 className="text-4xl font-bold mb-10">Contact</h1>
        <p className="text-lg mb-10">
          If you would like to get in touch, please send an email to{" "}
          <a
            href="mailto:am@brandiaq.com"
            className="text-blue-500 hover:underline"
          >
            am@brandiaq.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
