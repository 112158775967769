
import klmImg from "../images/klm.png";
import fodImg from "../images/freeofdesire.png";
import enriicoImg from "../images/enriico.png";
import klubImg from "../images/klub.png";
import readImg from "../images/read.png";
import rkImage from "../images/rk/home1.png";
import saImage from "../images/securelyattached/workpage-cover.png";
import reminisImg from "../images/rmns.png";
import reminisFooterImg from "../images/reminis/footer-cover.png";
import reminisCoverImg from "../images/reminis/cover.png";
import reminisChallenge from "../images/reminis/reminis-home.png";
import buxtImg from "../images/buxt.png";
import buxtCoverImg from "../images/buxt/cover.png";
import buxtChallengeImg from "../images/buxt/buxt-challenge.png";
import buxtFooterImg from "../images/buxt/footer-cover.png";
import buxtChallengeImg1 from "../images/buxt/buxt-challenge-1.png";
import reminisSignUp from '../images/reminis/reminis-signup.png';
import saCoverImg from '../images/securelyattached/cover.png';
import saFooterImg from '../images/securelyattached/footer-cover.png';
import saChallenge from '../images/securelyattached/sa-challenge.png';
import saChallenge1 from '../images/securelyattached/sa-challenge-1.png';
import enriiChallenge from '../images/enrii/challenge.png';
import enriiChallenge1 from '../images/enrii/challenge1.png';
import enriiFooterImg from '../images/enrii/footer-cover.png';
import rkCoverImg from '../images/rk/cover.png';
import rkChallengeImg from '../images/rk/logo.png';
import rkChallengeImg1 from '../images/rk/labels.png';
import rkFooterImg from '../images/rk/footer-cover.png';
import klubCoverImg from '../images/klub/cover.png';
import klubFooterImg from '../images/klub/footer-cover.png';
import klubChallengeImg from '../images/klub/blue.png';
import klubChallengeImg1 from '../images/klub/orange.png';
import readCoverImg from '../images/read/cover.png';
import readChallengeImg from '../images/read/challenge.png';
import readChallengeImg1 from '../images/read/challenge-1.png';
import readFooterImg from '../images/read/footer-cover.png';
import klmCoverImg from '../images/klm/klm.gif';
import klmChallengeImg from '../images/klm/challenge.png';
import klmChallengeImg1 from '../images/klm/challenge1.png';
import klmFooterImg from '../images/klm/footer-cover.png';

export const projects = [
  {
    id: 1,
    slug: "klm-arch",
    title: "KLM ARCH—Empowering business growth through scalable branding, digital strategy and design.",
    category: ["E-commerce", "All", "Brand", "Web"],
    image: klmImg, // WorkPage image
    description: "Branding, E-commerce, Catalog Design",
    colSpan: "col-span-full md:col-span-4",
    titleProject: "Empowering business growth through scalable branding, digital strategy and design.",
    type: "Web",
    industry: "Architecture & Interiors",
    contribution: "Brand Identity, Web Design",
    coverImage: klmCoverImg, // Cover image for the ProjectHero

    concept: "Creating a calming, warm-minimalistic experience for users to reconnect with their surroundings.",
    challengeDesc: "The project involved a thorough redesign of the brand's digital presence, where I prioritized an enhanced experience that was scalable. This overhaul ensured the brand could strengthen its identity while seamlessly expanding its reach across both existing and new markets. ",
    challengeImage: klmChallengeImg,
    challengeImage1: klmChallengeImg1,
    testimonialText: "You articulatd who KLM ARCH is to a T through your work, from brand to all our digital experience! Since the redesign, we've seen remarkable growth in both our client base and brand awareness. Alexandra’s work transcends design. She understood our business goals and created a platform that not only captures our brand but also supports our growth. Her strategic insight has been key in helping us build a lasting, scalable solution.",
    testimonialAuthor: "Henrik Watts",
    authorRole: "Founder",
    authorCompany: "KLM ARCH",
    footerImage: klmFooterImg, // Footer image for ProjectFooter
  },
  
  {
    id: 2,
    slug: "buxt",
    title: "BUXT—The toolkit empowering indie developers to master branding and UX for their games.",
    category: ["All", "Brand", "Product", "Web"],
    image: buxtImg,
    description: "Branding, UX/UI, Product/Toolkit Design",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Designing the toolkit for indie developers to master branding & UX",
    type: "Product/Toolkit Design",
    industry: "Gaming/Creative Tools",
    contribution: "Lead Designer, Strategist & Developer",
    coverImage: buxtCoverImg, 
    concept: "BUXT is a robust, self-guided toolkit that I developed to bridge a critical gap in the indie game development world: the absence of accessible, professional branding and UX resources.",
    challenge: "How do we create a branding and UX toolkit that is accessible, actionable, and adaptable for indie developers with limited resources?",
    challengeDesc: "Indie developers excel in creating innovative games but often struggle with branding and user experience due to resource limitations. The challenge for me was to create a toolkit that democratizes access to branding and UX best practices, providing developers with actionable, professional-grade resources that fit their budget and workflow.",
    challengeImage: buxtChallengeImg,
    challengeImage1: buxtChallengeImg1,
    testimonialText: "BUXT was built to be a guiding light for indie developers who often lack the resources or knowledge for branding and UX. The toolkit needed to be user-friendly yet impactful, empowering developers to take charge of their game's identity and user experience without needing a professional team.",
    testimonialAuthor: "AM",
    authorRole: "Designer, Developer, Strategist",
    authorCompany: "BUXT",
    footerImage: buxtFooterImg, 
},
{
    id: 3,
    slug: "reminis",
    title: "Reminis—The app designed to reconnect you with your cherished memories.",
    category: ["Brand", "Product", "All"],
    image: reminisImg,
    description: "Branding, UX/UI, Product/App Design",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Designing the AI-driven experience that reconnects users with their lost loved ones",
    type: "Product/App Design",
    industry: "Healthcare/Mental Health",
    contribution: "Lead Designer & Strategist",
    coverImage: reminisCoverImg,
    concept: "Reminis is a groundbreaking mobile app that allows users to continue interacting with deceased loved ones by leveraging AI to recreate their communication style. ",
    challenge: "How do we make AI-driven communication feel personal and respectful for grieving users?",
    challengeDesc: "As society becomes more digital, how we remember and engage with memories of lost loved ones is changing. The challenge was to design an app that could explore this evolving relationship while remaining emotionally supportive and ethically sound.",
    challengeImage: reminisChallenge,
    challengeImage1: reminisSignUp,
    testimonialText: "This is a reality-defying concept and the product design had to be no exception. I had to go beyond the usual user and human-centered approach, and consider reflective design processes, particularly critical design, to achieve a greater goal: creating a respectful, engaging experience that transcended traditional methods while maintaining ethical boundaries.",
    testimonialAuthor: "AM",
    authorRole: "Designer",
    authorCompany: "Reminis",
    footerImage: reminisFooterImg,
  },
  {
    id: 4,
    slug: "enriico",
    title: "EnriiCo—Bringing nature and science together through skincare branding.",
    category: ["E-commerce", "All", "Brand", "Campaign", "Web"],
    image: enriicoImg,
    description: "Branding, UX/UI, E-commerce, Packaging",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Bringing nature and science together through skincare branding.",
    type: "E-commerce",
    industry: "Beauty & Skincare",
    contribution: "Brand Strategist, Digital Experience Designer (E-commerce), Packaging.",
    coverImage: enriicoImg,
    concept: "ENRII Co. harmonizes nature and science through a thoughtfully designed skincare brand that promotes both sustainability and efficacy. My work focused on translating this balance into every aspect of the brand—from the packaging design that reflects purity and simplicity to a refined e-commerce experience that guides customers on a journey of mindful self-care.",
    challengeDesc: "How might we craft an identity and consequently an e-commerce platform and packaging that communicate ENRII Co.’s unique blend of science, nature, and mindfulness while providing a smooth and delightful customer experience?.",
    challengeImage: enriiChallenge,
    challengeImage1: enriiChallenge1,
    testimonialText: "The refreshed brand and e-commerce experience have really resonated with our customers. We've noticed an uptick in engagement and conversions, and the way our products are presented—both online and in person—has truly elevated the customer experience. ",
    testimonialAuthor: "Emily Sanchez",
    authorRole: "Founder",
    authorCompany: "EnriiCo",
    footerImage: enriiFooterImg,
  },
  {
    id: 10, 
    slug: "read",
    title: "read—Redefining the reading experience through product design and dev.",
    category: [ "Product", "All", "Brand"],
    image: readImg,
    description: "Branding, UX/UI, Product Design",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Redefining the reading experience through product design and development.",
    type: "Product Design",
    industry: "EdTech / Digital Reading",
    contribution: "Lead Product Designer",
    coverImage: readCoverImg,
    concept: "read is a revolutionary digital platform that reimagines the reading experience for the modern age. By blending traditional reading formats with cutting-edge technology, it creates an immersive and interactive environment where readers can dive deep into stories, connect with characters, and explore narratives in ways never before possible.",
    challengeDesc: "How do we engage digital readers in a more interactive and user-friendly way? We created an intuitive and user-focused reading experience, bridging traditional and modern reading formats.",
    challengeImage: readChallengeImg,
    challengeImage1: readChallengeImg1,
   /*  testimonialText: "The platform is engaging and makes reading feel both familiar and futuristic.",
    testimonialAuthor: "James Lawson",
    authorRole: "Product Manager", */
    authorCompany: "read",
    footerImage: readFooterImg,
  },
  {
    id: 6,
    slug: "rk",
    title: "RK—Positioning candles as high-end yet cozy must-haves.",
    category: ["All", "Brand"],
    image: rkImage,
    description: "Branding, Graphic Design",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Branding candles as must-have luxe lifestyle products.",
    type: "Branding",
    industry: "Home & Lifestyle",
    contribution: "Branding, Identity Design, Packaging Design",
    coverImage: rkCoverImg,
    concept: " RK was a new entrant into the candle market, seeking to position their products in the luxury segment while still offering an emotional appeal that made them feel approachable. They wanted to be known not just for high-quality candles, but for creating an experience that customers could associate with warmth, relaxation, and comfort in their homes.",
    challengeDesc: " How to make candles appeal as both cozy and high-end products? The challenge was to position RK candles as both luxurious and cozy lifestyle products, a unique combination that would resonate with both upscale consumers and those looking for comfort in their homes.",
    challengeImage: rkChallengeImg,
    challengeImage1: rkChallengeImg1,
    testimonialText: "Our brand now feels premium while staying accessible to a wide audience.",
    testimonialAuthor: "Rebecca Burns",
    authorRole: "Creative Director",
    authorCompany: "RK",
    footerImage: rkFooterImg,
  },
  {
    id: 7,
    slug: "securelyattached",
    title: "SecurelyAttached—A digital journey toward earned secure attachment ",
    category: ["Web Design", "UX/UI", "All", "Brand"],
    image: saImage,
    description: "Branding, Web Design, UX/UI",
    colSpan: "col-span-full md:col-span-4",
    titleProject: "Helping relationships through a digital-first approach to attachment theory.",
    type: "Branding, UX/UI Design, Web",
    industry: "Healthcare/Relationships",
    contribution: "Lead Strategist & Designer",
    coverImage: saCoverImg,
    concept: "SecurelyAttached is a clean, user-friendly digital space that fosters healing through understanding attachment styles. The core of the platform features guided exercises, reflective journaling prompts, articles and a community space aimed at transitioning insecure attachment patterns into earned secure attachment.",
    challengeDesc: "SecurelyAttached is about the vision of creating a mental health platform that addressed attachment healing. I led the branding, design and experience strategy efforts, with the goal of building a platform that felt both approachable and scientifically backed.",
    challengeImage: saChallenge,
    challengeImage1: saChallenge1,/* 
    testimonialText: "This is a rather serious topic, with a lot of intricate psychological processes at play. The design of the platform needed to take that and simplify it in a way that is both engaging and accessible, which was done beautifully.",
    testimonialAuthor: "Dr. Angela Burns",
    authorRole: "CEO", */
    authorCompany: "SecurelyAttached",
    footerImage: saFooterImg,
  },
  
  {
    id: 9,
    slug: "klub",
    title: "klub—Designing AI-driven B2B software for redefining team collab.",
    category: ["Web", "Product", "All"],
    image: klubImg,
    description: "Branding, UX/UI/ Product",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Designing AI-driven B2B software for redefining team collab.",
    type: "B2B Software",
    industry: "Tech",
    contribution: "UX/UI, AI Strategy",
    coverImage: klubCoverImg,
    concept: "Leveraging AI to revolutionize B2B team collaboration -integrated machine learning algorithms to provide personalized solutions for effective team management.",
    challenge: "How can we integrate AI to improve team collaboration?",
    challengeDesc: "How might we simplify AI-driven data insights to help teams collaborate more efficiently?",
    challengeImage: klubChallengeImg,
    challengeImage1: klubChallengeImg1,
    testimonialText: "Alexandra's design made complex AI processes easy to navigate. Her work has simplified our platform, making it both powerful and user-friendly, which our users appreciate immensely.",
    testimonialAuthor: "Sarah Lee",
    authorRole: "CTO",
    authorCompany: "klub",
    footerImage: klubFooterImg,
  },
  
  {
    id: 11,
    slug: "freeofdesire",
    title: "FreeOfDesire—The app designed to reconnect you with your cherished memories.",
    category: ["Web", "Analog", "All", "Brand"],
    image: fodImg,
    description: "Branding, Web, Analog",
    colSpan: "col-span-full md:col-span-2",
    titleProject: "Revamping the fashion e-commerce space with unique and elegant user experiences.",
    type: "Web Design",
    industry: "Fashion",
    contribution: "Brand Strategy, Web Development",
    coverImage: fodImg,
    concept: "FreeOfDesire focuses on revamping the fashion e-commerce space with unique and elegant user experiences.",
    challengeDesc: " How to balance bold fashion statements with functional web design? We implemented striking visual aesthetics while maintaining a seamless shopping experience.",
    testimonialText: "The result exceeded our expectations. The branding and web presence truly stand out.",
    testimonialAuthor: "Catherine Cole",
    authorRole: "CEO",
    authorCompany: "FreeOfDesire",
    footerImage: fodImg,
  },
];
