import React from 'react';

const ProjectFooter = ({ testimonialText, testimonialAuthor, authorRole, authorCompany, footerImage }) => {
  return (
    <section className="bg-black text-white py-12 px-8">
      <div className="max-w-[1305px] mx-auto">
         {/* Footer Image */}
         <div className="mt-12">
          <img src={footerImage} alt="Footer" className="w-full" />
        </div>
        {/* Testimonial Section */}
        <div className="mt-12">
          <blockquote className="text-2xl max-w-[550px]">"{testimonialText}"</blockquote>
          <p className="mt-4 text-lg">
            {testimonialAuthor} </p>
            <p className=" text-xs">
              {authorRole}, {authorCompany}
            </p>
        </div>
      </div>
    </section>
  );
};

export default ProjectFooter;
