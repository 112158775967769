import React from 'react';
import HeroSection from '../components/HeroSection';
import FeaturedWork from '../components/FeaturedWork';
import MyApproach from '../components/MyApproach';
import AboutSection from '../components/AboutSection';

const Homepage = () => {
  return (
    <div>
      <HeroSection />
      <FeaturedWork />
      <MyApproach />
      <AboutSection />
    </div>
  );
};

export default Homepage;
